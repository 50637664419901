const color = {
  methods: {
    color(status) {
      switch (status) {
        case 1: // activo
          return "#2196f3";
          break;
        case 2:
          return "black"; // expirado
          break;
        case 3:
          return "#051838"; // aprobado
          break;
        case 4:
          return "red darken-4"; // rechazado
          break;
        case 5:
          return "blue-grey lighten-4"; // leido
          break;
        case 6:
          return "blue-grey lighten-4"; // no leido
          break;
        case 7:
          return "red darken-4"; // rechazado director de operaciones
          break;
        case 8:
          return "red darken-4"; // rechazado gerente de operaciones
          break;
        case 9:
          return "blue darken-4"; // Aprobado director operativo
          break;
        case 10:
          return "pink darken-4"; // Aprobado gerente operativo
          break;
        case 11:
            return "orange"; // Aprobado gerente operativo
            break;
        case 15:
          return "green darken-3"; // Completado
          break;
        case 16:
          return "red darken-4"; // Rechazado coordinador
          break;
        case 17:
          return "deep-purple darken-4"; // Aprobado coordinador
          break;
        case 18:
          return "orange"; // Pendiente coordinador
          break;
        case 19:
          return "red darken-4"; // Rechazado gerente cuenta
          break;
        case 20:
          return "#034f79"; // Aprobado gerente cuenta más importante 
          break;
        case 21:
          return "pink darken-4"; // pendiente gerente operativo
          break;
        case 22:
          return "#034f79"; //pendiente gerente cuenta 
          break;
        default:
          return "black";
          break;
      }
    },
  },
};
export default color;
