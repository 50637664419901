var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-0 pt-0 px-5",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-layout',{attrs:{"justify-center":""}},[_c('h2',{staticClass:"color-text-main pt-4"},[_vm._v("LISTA DE COMISIONES")])])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn-toggle',{attrs:{"color":"#E95233","group":""},model:{value:(_vm.actionCommission),callback:function ($$v) {_vm.actionCommission=$$v},expression:"actionCommission"}},[_c('v-btn',{attrs:{"outlined":"","value":"course"}},[_vm._v(" COMISIONES EN CURSO ")]),_c('v-btn',{attrs:{"outlined":"","value":"history"}},[_vm._v(" HISTORIAL DE COMISIONES ")]),_c('v-btn',{attrs:{"outlined":"","value":"download"}},[_vm._v(" DESCARGAR CONSOLIDADOS ")])],1)],1),(!_vm.show.table)?[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":_vm.colSearch}},[_c('v-text-field',{staticClass:"pt-5",attrs:{"prepend-inner-icon":"mdi-magnify","clearable":"","label":"Buscar","placeholder":"Ingrese aquí lo que desea buscar","color":"#034f79","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.show.btn)?_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"3"}},[_c('v-layout',{attrs:{"justify-end":""}},[_c('v-btn',{staticClass:"mt-5",attrs:{"to":{
            name: 'commissionDetailsPayRollManager',
            params: { commissionId: _vm.commissionId[0].id },
          },"block":"","color":"#034f79","dark":""}},[_vm._v(" DETALLES ")])],1)],1):_vm._e(),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"my-2"}),_c('v-data-table',{attrs:{"return-object":"","headers":_vm.headers,"items":_vm.items,"single-select":"","show-select":"","items-per-page":_vm.itemsPerPage,"page":_vm.page,"loading":_vm.loading.table,"search":_vm.search,"loading-text":"Cargando...","hide-default-footer":""},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"icon":"mdi-information-outline","prominent":"","text":"","type":"info"}},[_c('b',[_vm._v("No se encontraron resultados con: "+_vm._s(_vm.search))])])]},proxy:true},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"icon":"mdi-information-outline","prominent":"","text":"","type":"info"}},[_c('b',[_vm._v("No se encontró información.")])])]},proxy:true},{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"#214484","height":5,"indeterminate":"","stream":""}})]},proxy:true},{key:"item.statusVal",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","dark":"","small":"","color":_vm.getColorStatus(item.status_id)}},[_vm._v(" "+_vm._s(item.status.name)+" ")])]}}],null,false,1105378728),model:{value:(_vm.commissionId),callback:function ($$v) {_vm.commissionId=$$v},expression:"commissionId"}}),_c('v-divider',{staticClass:"my-2"})],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"6"}},[_c('v-layout',{attrs:{"justify-start":""}},[_c('span',{staticClass:"grey--text pr-4 pt-2"},[_vm._v("# de items por página")]),_c('v-select',{staticClass:"select-page",staticStyle:{"width":"80px"},attrs:{"dense":"","items":_vm.itemsPerPageArray,"label":"# de items por página","solo":"","color":"#034f79"},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1)],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"6"}},[_c('v-layout',{attrs:{"justify-end":""}},[_c('span',{staticClass:"mr-4 grey--text pt-1"},[_vm._v(" Página "+_vm._s(_vm.page)+" de "+_vm._s(_vm.numberOfPages)+" ")]),_c('v-btn',{staticClass:"mr-1",attrs:{"x-small":"","fab":"","dark":"","color":"#E95233"},on:{"click":_vm.formerPage}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{staticClass:"ml-1",attrs:{"x-small":"","fab":"","dark":"","color":"#E95233"},on:{"click":_vm.nextPage}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1)]:_vm._e(),(_vm.show.table)?_c('v-col',{attrs:{"cols":"5"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-3",attrs:{"label":"Seleccione un rango de fechas","prepend-icon":"mdi-calendar","readonly":"","outlined":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}],null,false,843647312),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"range":"","no-title":"","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" cancelar ")]),_c('v-btn',{attrs:{"loading":_vm.loading.table,"text":"","color":"primary"},on:{"click":function($event){return _vm.consolidated(_vm.date)}}},[_vm._v(" Consultar ")])],1)],1)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }